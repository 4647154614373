export const ReddReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_MARK_DONATED':
      if(action.id){
        const markArr = [action.id,...state]
        return [...new Set(markArr)]
      }
    case 'REMOVE_MARK_DONATED':
      return state.filter(id => id !== action.id);
    case 'UPDATE_LOCAL_DATA':
      return [...action.value];
    default:
      return state;
  }
} 
import React, { createContext,useState, useReducer, useEffect } from 'react';
import { ReddReducer } from './reducer';

export const ReddContext = createContext();

const ReddContextProvider = ({children}) => {
  const [hasInit, setHasInit] = useState(false);
  const [redd, dispatch] = useReducer(ReddReducer, []);
  useEffect(()=>{
    const localData = localStorage.getItem('redd');
    const localRedd = localData ? JSON.parse(localData) : [];
    if(Array.isArray(localRedd) && localRedd.length){
      dispatch({
        type: 'UPDATE_LOCAL_DATA',
        value: localRedd
      })
    }
    setHasInit(true);
  },[])
  useEffect(() => {
    if(hasInit){
      localStorage.setItem('redd', JSON.stringify(redd));
    }
  }, [redd]);
  return (
    <ReddContext.Provider value={{ redd, dispatch }}>
      {children}
    </ReddContext.Provider>
  );
}
 
export default ReddContextProvider;
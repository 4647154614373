import React, { useContext } from "react"
import styled from "styled-components"

import useFormatLocale from "@/hooks/useFormatLocale"
import Image from "@/components/Image"
import artworkBg from "../../icons/artwork.svg"

import { ReddContext } from "@/contexts/Redd"

const styledCard = {
  // card main color
  bg: "#fbe492",
  // card border color
  border: "#fefdf0",
  // tick highlight color
  icon: "#7a6937",
  // tick border color
  line: "#fbe492",
  // checkbox border color
  fill: "#f9f4b8",
}

const ArtworkCard = ({ artwork }) => {
  const lng = useFormatLocale()
  const { name, icon, size, isDonated, hasFake } = artwork
  const { dispatch } = useContext(ReddContext)

  const handleCheckbox = () => {
    if (isDonated) {
      dispatch({ type: "REMOVE_MARK_DONATED", id: artwork.id })
    } else {
      dispatch({ type: "ADD_MARK_DONATED", id: artwork.id })
    }
  }

  return (
    <StyledArtworkCard bg={artworkBg}>
      <div
        style={{
          borderRadius: `2px`,
          boxShadow: `0 0 0 6px ${styledCard.border}`,
          backgroundColor: `${styledCard.border}`,
        }}
      >
        <div className="artwork-bg">
          <div className={`artwork-icon ${size}`}>
            <Image src={icon.childImageSharp.fluid} />
          </div>
          {!!hasFake && (
            <div className="artwork-fake">
              <FakeMarkIcon color={styledCard} />
            </div>
          )}
          {isDonated && (
            <div className="artwork-donation">
              <DonationMarkIcon color={styledCard} />
            </div>
          )}
          <div
            className="artwork-checkbox"
            onClick={handleCheckbox}
            style={{
              boxShadow: `0 0 0 3px ${styledCard.fill}`,
            }}
          >
            <CheckMarkIcon isMark={isDonated} color={styledCard} />
          </div>
        </div>
      </div>
      <span className="artwork-title">{name[lng]}</span>
    </StyledArtworkCard>
  )
}

export default ArtworkCard

const StyledArtworkCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  @media (max-width: 450px) {
    width: calc(50vw - 20px);
  }
  .artwork-bg {
    width: 124px;
    height: 184px;
    background-size: cover;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background-image: url(${(props) => props.bg});
    .artwork-icon {
      cursor: pointer;
      width: 72%;
      &.large {
        width: 100%;
      }
      &.small {
        width: 65%;
      }
      &.x-small {
        width: 57%;
      }
      & > div {
        width: 100%;
      }
    }
  }
  .artwork-title {
    margin-top: 18px;
    max-width: 124px;
    text-align: center;
  }
  .artwork-fake {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .artwork-donation {
    position: absolute;
    bottom: 3px;
    left: 1px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .artwork-checkbox {
    position: absolute;
    right: 6px;
    bottom: 6px;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    cursor: pointer;
    background-color: transparent;
    svg {
      position: absolute;
      left: -4px;
      top: -2px;
      width: 26px;
      path {
        transition: 0.3s fill;
      }
    }
  }
`

const FakeMarkIcon = ({ color }) => {
  return (
    <svg viewBox="0 0 33 35">
      <g fill={color.icon} transform="translate(-1.000000, -1.000000)">
        <g transform="translate(17.446580, 18.211186) scale(-1, 1) rotate(-180.000000) translate(-17.446580, -18.211186) translate(0.946580, 0.211186)">
          <path d="M32.5016343,34.1904104 C32.8016343,33.6904104 31.7016343,31.3904104 30.0016343,29.0904104 C26.0016343,23.5904104 25.8016343,15.8904104 29.5016343,7.69041035 L31.9016343,2.09041035 L29.4016343,1.19041035 C24.3016343,-0.809589647 11.7016343,0.490410353 8.60163429,3.29041035 C8.20163429,3.69041035 9.60163429,5.99041035 11.9016343,8.29041035 C14.1016343,10.6904104 16.0016343,12.8904104 16.0016343,13.2904104 C16.0016343,13.6904104 14.1016343,12.9904104 11.8016343,11.6904104 C4.20163429,7.59041035 3.40163429,7.59041035 1.60163429,12.1904104 C-0.0983657143,16.3904104 -0.398365714,21.9904104 0.701634286,25.9904104 C1.10163429,27.7904104 1.80163429,28.2904104 3.40163429,27.7904104 C4.60163429,27.5904104 8.20163429,26.5904104 11.5016343,25.7904104 C21.1016343,23.2904104 27.3016343,25.6904104 28.7016343,32.2904104 C29.2016343,35.0904104 31.3016343,36.1904104 32.5016343,34.1904104 Z"></path>
        </g>
      </g>
    </svg>
  )
}

const CheckMarkIcon = ({ isMark, color }) => {
  const mainColor = isMark ? color.icon : color.fill
  const lineColor = isMark ? color.fill : color.line
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 59 47">
      <g transform="translate(0.000000, -1.000000)">
        <g transform="translate(29.500000, 24.180919) scale(-1, 1) rotate(-180.000000) translate(-29.500000, -24.180919) translate(0.000000, 0.680919)">
          <path
            fill={lineColor}
            d="M47,46.3 C46.2,46 40.4,41.4 34.2,36.2 L22.9,26.7 L19.7,29.8 C16.1,33.3 10.8,34.1 7.7,31.8 C4.5,29.3 0,22.7 0,20.4 C0,15.8 16.9,0 21.8,0 C24.7,0 30.1,5.2 48.8,25.8 C58.7,36.7 59.8,39.6 56.3,43.9 C54.4,46.1 49.6,47.4 47,46.3 Z"
          ></path>
          <path
            fill={mainColor}
            d="M52,40.5 C52.6,39.7 52.8,38.5 52.4,37.8 C50.7,35 22.7,5 21.8,5 C20.3,5 5,19 5,20.3 C5,22.1 11.2,28 13.1,28 C14.1,28 16.3,26.2 18,24 C19.7,21.8 21.6,20 22.1,20 C22.7,20 28.9,24.9 36.1,31 C43.2,37.1 49.5,42 49.9,42 C50.4,42 51.3,41.3 52,40.5 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

const DonationMarkIcon = ({ color }) => {
  return (
    <svg viewBox="0 0 94 119">
      <g transform="translate(-1.000000, -1.000000)">
        <g transform="translate(47.918142, 60.298447) scale(-1, 1) rotate(-180.000000) translate(-47.918142, -60.298447) translate(0.918142, 0.798447)">
          <path
            d="M26.3,106.9 C31.6,105.6 37,105.2 48.3,105.3 C62.2,105.5 68.4,106.4 76.3,109.4 C77.5,109.9 77.8,109.1 77.8,105.3 C77.8,102.8 77.3,98.7 76.7,96.3 C75.7,92.3 75.9,90.9 79.2,81.1 C88,54.8 87.8,42.8 78.7,32.4 C74.1,27.2 70.9,25.3 62.8,22.8 C59.8,21.8 57.2,20.6 57.1,20.1 C56.9,19.6 58.6,18.3 60.8,17.3 C64.8,15.5 65.5,14.4 63.9,12 C63.2,10.8 62.5,10.9 59.9,12.2 L56.8,13.9 L56.8,10.8 C56.8,8.3 56.4,7.8 54.3,7.8 C52.3,7.8 51.8,8.3 51.8,10.4 C51.8,12.5 51.5,12.9 50.1,12.3 C47.8,11.3 45.8,11.3 43.5,12.3 C42.1,12.9 41.8,12.5 41.8,10.4 C41.8,8.3 41.3,7.8 39.3,7.8 C37.2,7.8 36.8,8.3 36.8,10.8 L36.8,13.9 L33.7,12.3 C32,11.4 30.5,10.8 30.4,11 C30.3,11.2 29.6,12.1 28.9,13 C27.8,14.5 28.1,15 32.2,17 C34.8,18.2 36.7,19.6 36.5,20.1 C36.4,20.6 33.8,21.8 30.8,22.8 C22.7,25.3 19.5,27.2 14.9,32.4 C5.8,42.8 5.6,54.8 14.4,81.1 C17.7,90.9 17.9,92.3 16.9,96.3 C16.3,98.7 15.8,102.8 15.8,105.3 C15.8,109.4 16,109.9 17.6,109.3 C18.5,108.9 22.5,107.9 26.3,106.9 Z"
            fill={color.icon}
          ></path>
          <path
            d="M12.1680838,117.8 C9.16808381,116.4 9.2,113.6 8.4,109.5 C7.7,106.3 7.8,102.4 8.5,98.2 C9.6,92.2 9.4,91.3 6.3,81.6 C-0.5,60.6 -1.6,48.9 2.4,37.5 C4.2,32.3 11.9,23.2 17.3,19.8 C19.9,18.2 20.6,17.1 20.6,14.9 C20.5,11.2 23.7,5.7 26.9,4.1 C35.5,-0.1 37.2,-0.5 42,0.5 C45.1,1.1 48.5,1.1 51.6,0.5 C56.4,-0.5 58.1,-0.1 66.7,4.1 C69.9,5.7 73.1,11.2 73,14.9 C73,17 73.7,18.2 76.1,19.7 C81.5,23 87.6,30 90.3,36 C95.4,47.1 94.6,59.1 87.3,81.8 C84.2,91.2 84,92.8 84.9,96.8 C85.5,99.3 85.8,103.9 85.5,107.1 C84.6,116.9 80.8,119 69.8,115.6 C64.4,113.8 60.6,113.5 46.8,113.5 C32.7,113.5 29.3,113.8 23.4,115.7 C19.7,116.8 16.5,117.8 16.4,117.8 C16.4,117.8 14.0680838,118.8 12.1680838,117.8 Z M26.3,106.9 C31.6,105.6 37,105.2 48.3,105.3 C62.2,105.5 68.4,106.4 76.3,109.4 C77.5,109.9 77.8,109.1 77.8,105.3 C77.8,102.8 77.3,98.7 76.7,96.3 C75.7,92.3 75.9,90.9 79.2,81.1 C88,54.8 87.8,42.8 78.7,32.4 C74.1,27.2 70.9,25.3 62.8,22.8 C59.8,21.8 57.2,20.6 57.1,20.1 C56.9,19.6 58.6,18.3 60.8,17.3 C64.8,15.5 65.5,14.4 63.9,12 C63.2,10.8 62.5,10.9 59.9,12.2 L56.8,13.9 L56.8,10.8 C56.8,8.3 56.4,7.8 54.3,7.8 C52.3,7.8 51.8,8.3 51.8,10.4 C51.8,12.5 51.5,12.9 50.1,12.3 C47.8,11.3 45.8,11.3 43.5,12.3 C42.1,12.9 41.8,12.5 41.8,10.4 C41.8,8.3 41.3,7.8 39.3,7.8 C37.2,7.8 36.8,8.3 36.8,10.8 L36.8,13.9 L33.7,12.3 C32,11.4 30.5,10.8 30.4,11 C30.3,11.2 29.6,12.1 28.9,13 C27.8,14.5 28.1,15 32.2,17 C34.8,18.2 36.7,19.6 36.5,20.1 C36.4,20.6 33.8,21.8 30.8,22.8 C22.7,25.3 19.5,27.2 14.9,32.4 C5.8,42.8 5.6,54.8 14.4,81.1 C17.7,90.9 17.9,92.3 16.9,96.3 C16.3,98.7 15.8,102.8 15.8,105.3 C15.8,109.4 16,109.9 17.6,109.3 C18.5,108.9 22.5,107.9 26.3,106.9 Z"
            fill={color.border}
          ></path>
          <path
            d="M70.8,84.9 C70.8,88.8 62.9,91.5 60,88.6 C58.2,86.8 58.6,82.3 60.6,80.5 C64.4,77 70.8,79.8 70.8,84.9 Z M46.8,73.7 L51.4,76.8 C55.3,79.5 55.7,80.2 54.7,81.6 C52.4,84.6 47.4,89.8 46.8,89.8 C46.5,89.8 44.8,88.3 43.2,86.6 C37.2,80.1 37.2,80.3 42.2,76.8 L46.8,73.7 Z M33.1,80.6 C35.1,82.8 35.3,86.9 33.6,88.6 C31.8,90.4 26.3,90 24.5,88 C22.3,85.5 22.4,83.2 24.8,80.8 C27.3,78.3 31,78.2 33.1,80.6 Z M28.3,44.8 L32.1,49.8 L35.8,54.8 L20.7,54.8 L22.7,52.1 C23.8,50.5 25.6,48.3 26.5,47 L28.3,44.8 Z M46.7,45.3 L50.2,49.3 C52.2,51.5 53.7,53.6 53.8,54.1 C53.8,54.4894737 50.8610111,54.7841413 47.1061908,54.7993804 L46.8,54.8 C43,54.8 39.8,54.5 39.8,54.1 C39.8,53.6 41.4,51.5 43.3,49.3 L46.7,45.3 Z M65.3,45 L67.1,47.1 C68.1,48.3 69.8,50.5 70.9,52.1 L72.9,54.8 L57.7,54.8 L65.3,45 Z M37.6,33.8 C38.1,33.8 39.2,35.1 42.9,40.1 L44.9,42.8 L29.7,42.8 L33.4,38.3 C35.4,35.8 37.3,33.8 37.6,33.8 Z M59.4,37.3 C61.2,39.5 62.8,41.6 62.8,42.1 C62.8,42.4894737 59.766205,42.7841413 56.0063949,42.7993804 L48.7,42.8 L50.7,40.1 C56.3,32.6 55.6,32.8 59.4,37.3 Z M49.8,17.3 C54.3,19.6 53.4,20.8 47.3,20.8 C44.3,20.8 41.8,20.4 41.8,19.9 C41.8,19.4 42.9,18.3 44.3,17.4 C46.6,15.9 47.1,15.9 49.8,17.3 Z"
            fill={color.border}
          ></path>
        </g>
      </g>
    </svg>
  )
}

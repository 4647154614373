import React, { useState, useContext, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import ArtworkCard from "@/components/ArtworkCard"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"
import NoMatch from "@/components/NoMatch"

import ReddContextProvider, { ReddContext } from "@/contexts/Redd"
import useTranslation from "@/hooks/useTranslation"
import createNodeId from "@/utils/createNodeId"

const ReddArtPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allReddYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          artworks {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            realArtwork {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            size
            hasFake
            type
          }
        }
      }
    }
  `)

  const t = useTranslation()
  const { artworks, ...ReddInfo } = queryData.allReddYaml.nodes[0]
  const artworksHasMarked = artworks.map((artwork) => ({
    ...artwork,
    isDonated: false,
    id: createNodeId(artwork.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const [value3, setValue3] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  const handleChange3 = (event, newValue) => {
    setValue3(newValue)
  }

  return (
    <Layout>
      <PageContainer>
        <div className="header">
          <Image className="img" src={ReddInfo.avatar.childImageSharp.fluid} />
          <span>{t(ReddInfo.name)}</span>
        </div>
        <MultiLineTabs value={value} onChange={handleChange}>
          <TextTab label="all" />
          <TextTab label="painting" />
          <TextTab label="statue" />
        </MultiLineTabs>
        <MultiLineTabs value={value2} onChange={handleChange2}>
          <TextTab label="all" />
          <TextTab label="authentic" />
          <TextTab label="fake" />
        </MultiLineTabs>
        <MultiLineTabs value={value3} onChange={handleChange3}>
          <TextTab label="all" />
          <TextTab label="notDonated" />
          <TextTab label="donated" />
        </MultiLineTabs>
        <ReddContextProvider>
          <ArtworkCards
            artworks={artworksHasMarked}
            tabs={{ value, value2, value3 }}
          />
        </ReddContextProvider>
      </PageContainer>
    </Layout>
  )
}

export default ReddArtPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img {
      width: 150px;
      border-radius: 50%;
    }
    span {
      margin: 14px 0 18px;
    }
  }
`

const ArtworkCards = ({ artworks,tabs }) => {
  const { redd } = useContext(ReddContext)
  
  const currentArtworks = useMemo(() => {
    const { value, value2, value3 } = tabs

    const artworksHasMarked = artworks.map((artwork) => {
      const artworkHasMarked = redd.filter((id) => id === artwork.id)
      if (artworkHasMarked.length) {
        return {
          ...artwork,
          isDonated: true,
        }
      } else {
        return {
          ...artwork,
        }
      }
    })
    let artworksArr = [...artworksHasMarked]

    if (value === 1) {
      artworksArr = artworksArr.filter((artwork) => artwork.type==="painting")
    } else if (value === 2) {
      artworksArr = artworksArr.filter((artwork) => artwork.type==="statue")
    }

    if (value2 === 1) {
      artworksArr = artworksArr.filter((artwork) => !artwork.hasFake)
    } else if (value2 === 2) {
      artworksArr = artworksArr.filter((artwork) => artwork.hasFake)
    }

    if (value3 === 1) {
      artworksArr = artworksArr.filter((artwork) => !artwork.isDonated)
    } else if (value3 === 2) {
      artworksArr = artworksArr.filter((artwork) => artwork.isDonated)
    }

    return artworksArr
  }, [redd, artworks, tabs])

  return (
    <StyledArtworkCards>
      {currentArtworks.length ? (
        <div className="artwork_cards">
          {currentArtworks.map((artwork) => (
            <ArtworkCard artwork={artwork} key={artwork.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledArtworkCards>
  )
}

const StyledArtworkCards = styled.div`
  .artwork_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`
